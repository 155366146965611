<template>
  <el-dialog
    title="Ajouter un titre de formation continue"
    :visible.sync="dialogShowFcTitreCreate"
    width="460px"
  >
    <form-fconttitre ref="formFct" :catdesactive="catdesactive" />
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" type="primary" @click="fcOnSubmit"
        >Enregistrer</el-button
      >
      <el-button size="mini" @click="fcOnCancel">Annuler</el-button>
    </span>
  </el-dialog>
</template>
<script>
import FormFconttitre from "@/components/rh/ui/form/FormFconttitre";
import { strNormalize } from "@/fonctions";
export default {
  components: { "form-fconttitre": FormFconttitre },
  data() {
    return {};
  },
  props: {
    catdesactive: Boolean,
  },
  computed: {
    dialogShowFcTitreCreate: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.comp.dialogShowFcTitreCreate;
      },
      set(dialogShowFcTitreCreate) {
        this.$store.commit(
          "comp/setDialogShowFcTitreCreate",
          dialogShowFcTitreCreate
        );
      },
    },
  },
  methods: {
    fcOnSubmit() {
      this.$refs.formFct.$refs.formFconttitre.validate((valid) => {
        if (valid) {
          // récupère les données de la boîte de dialogue
          const bd = this.$store.state.fctit.titre;
          // enlever les espaces avant et après dans le nom de la nouvelle activité
          bd.titre = bd.titre.trim();
          // vérifie si l'activité est  déjà dans la bd
          const titresInBD = this.$store.getters["fctit/titresInBD"];
          // nom de l'activité  en minuscule et sans espace et sans accents pour la comparaison
          const fcNormalize = strNormalize(bd.titre);

          if (titresInBD.filter((m) => m[2] == fcNormalize).length > 0) {
            this.$alert(
              "Cette formation est déjà enregistrée dans la base de données !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            //crée un nouveau enregistrement
            this.$store.dispatch("fctit/createTitre", bd);
            // ferme la boîte de dialogue
            this.dialogShowFcTitreCreate = false;
          }
        }
      });
    },
    fcOnCancel() {
      // ferme la boîte de dialogue
      this.dialogShowFcTitreCreate = false;
      // reset le form
      this.$refs.formFct.$refs.formFconttitre.resetFields();
    },
  },
};
</script>
