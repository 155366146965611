<template>
  <div>
    <h1>Formation continue</h1>
    <el-row style="width: 820px" v-loading="fcontinueLoadStatus == 1">
      <el-col :span="12">
        <form-fcontinue :ismeddetail="ismeddetail" ref="formFcont" />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormFcontinue from "@/components/rh/ui/form/FormFcontinue";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-fcontinue": FormFcontinue },

  data() {
    return {
      loadCount: 0,
    };
  },
  props: {
    ismeddetail: Boolean,
    routetolist: Object,
  },
  created() {
    this.$store.dispatch("fc/loadFcontinue", this.idFcont);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idFcont() {
      // récupère le id le la fcontinue dans l'url
      return String(this.$route.params.idfc);
    },
    fcontinueLoadStatus() {
      return this.$store.state.fc.fcontinueLoadStatus;
    },
    fcontinueUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fc.fcontinueUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.fcontinueLoadStatus == 2) {
        // charge les données de la fcontinue
        const fc = this.$store.state.fc.fcontinue;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.fcLoaded = JSON.stringify(fc);
      } else if (this.fcontinueLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formFcont.$refs.formFcontinue.validate((valid) => {
        if (valid) {
          let fcdata = this.$store.state.fc.fcontinue;

          // enlever les espaces avant et après dans les string
          trimStringInObj(fcdata);

          const fcInBD = this.$store.getters["fc/FcontinueInBD"];
          const fcDate =
            String(fcdata.annee) + (fcdata.mois ? "-" + fcdata.mois : "");
          const id = this.idFcont;
          const doublon =
            fcInBD.filter(
              (m) =>
                m[0] != id &&
                m[1] == fcdata.id_med &&
                m[2] == fcdata.id_fcont_titre &&
                m[3] == fcDate
            ).length > 0;
          // prépare les données pour les comparer avec les données avant modifcation et pour l'enregistrement
          const fcdataStr = JSON.stringify(fcdata);

          // vérifie si les données ont été modifiées
          if (localStorage.fcLoaded == fcdataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si une fcontinue idendique existe déjà pour le médecin
          } else if (doublon) {
            this.$alert(
              "Ce titre avec cette date a déjà été enregistré pour ce médecin",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur
            //maj l'enregistrement
            this.$store.dispatch("fc/updateFcontinue", fcdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const fcStr = JSON.stringify(this.$store.state.fc.fcontinue);
      if (localStorage.fcLoaded != fcStr) {
        // si les données ont été modifiées
        let dataObj = JSON.parse(localStorage.fcLoaded);
        this.$store.commit("fc/setFcontinue", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // prépare les données pour les comparer avec les données avant modifcation
      const fc = this.$store.state.fc.fcontinue;
      const fcStr = JSON.stringify(fc);

      if (localStorage.fcLoaded == fcStr) {
        // si pas de modifications
        // retourne à la liste des formations post-graduées
        this.$router.push(this.routetolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des formations post-graduées
          this.$router.push(this.routetolist);
        });
      }
    },
  },
  watch: {
    fcontinueLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    fcontinueUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.fcontinueUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.fcLoaded = JSON.stringify(this.$store.state.fc.fcontinue);
      } else if (this.fcontinueUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
