<template>
  <div class="app-form">
    <el-form
      ref="formFcontinue"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <!-- Médecin -->
      <el-form-item
        label="Médecin"
        prop="id_med"
        v-show="!ismeddetail && !isdialog"
      >
        <el-select
          v-model="form.id_med"
          clearable
          filterable
          placeholder="Choisissez un médecin"
        >
          <el-option
            v-for="item in medecinOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- Catégorie -->
      <el-form-item label="Catégorie" prop="categorie" v-show="!isdialog">
        <el-select
          v-model="type"
          clearable
          filterable
          placeholder="Choisissez une catégorie"
          @change="resetTitreMois"
        >
          <el-option
            v-for="item in categorieOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- Titre -->
      <el-form-item label="Titre" prop="id_fcont_titre" v-show="!isdialog">
        <el-select
          ref="titre"
          v-model="form.id_fcont_titre"
          :disabled="desactive"
          clearable
          filterable
          placeholder="Choisissez un titre"
        >
          <el-option
            v-for="item in titreOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
          <el-option value="">
            <el-button type="text" @click="addFcont">
              <i class="el-icon-circle-plus-outline"></i
              ><i> Ajouter un titre</i>
            </el-button>
          </el-option>
        </el-select>
      </el-form-item>
      <!-- Précisions -->
      <el-form-item label="Précisions : " prop="autre_prec">
        <el-input v-model="form.autre_prec"></el-input>
      </el-form-item>
      <!-- Année -->
      <el-form-item label="Année" prop="annee">
        <el-select
          ref="annee"
          v-model="form.annee"
          clearable
          filterable
          placeholder="Choisissez une annnée"
          @change="changeAn"
        >
          <el-option
            v-for="item in anneeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- Mois -->
      <el-form-item label="Mois" prop="mois" v-show="moisShow">
        <el-select
          ref="mois"
          v-model="form.mois"
          clearable
          filterable
          placeholder="Choisissez un mois"
        >
          <el-option
            v-for="item in moisOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- boîte de dialogue pour ajouter un titre de fomation continue -->
    <dialogform-fconttitrecreate :catdesactive="true" />
  </div>
</template>
<script>
import DialogformFconttitrecreate from "@/components/rh/dialogbox/DialogformFconttitrecreate";

export default {
  components: { "dialogform-fconttitrecreate": DialogformFconttitrecreate },

  data() {
    var valideMois = (rule, value, callback) => {
      if (
        [2, 3, 4, 5, 6, 7, 8, 9].includes(this.form.id_fcont_titre) &&
        /^[0-9]{4}$/.test(this.form.annee)
      ) {
        if (value) {
          callback();
        } else {
          callback(new Error("Ce champ est obligatoire"));
        }
      } else {
        callback();
      }
    };

    return {
      rules: {
        id_med: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_fcont_titre: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        mois: [{ required: true, validator: valideMois, trigger: "change" }],
        annee: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
      },
      desactive: true,
      type: null,
    };
  },
  props: {
    ismeddetail: Boolean,
    isdialog: Boolean,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.fc.fcontinue;
      },
      set(form) {
        this.$store.commit("fc/setFcontinue", form);
      },
    },
    dialogShowFcTitreCreate: {
      get() {
        return this.$store.state.comp.dialogShowFcTitreCreate;
      },
      set(dialogShowFcTitreCreate) {
        this.$store.commit(
          "comp/setDialogShowFcTitreCreate",
          dialogShowFcTitreCreate
        );
      },
    },
    medecinOptions() {
      return this.$store.getters["medl/medecinSelect"];
    },
    categorieOptions() {
      return this.$store.getters["fccat/categorieSelect"];
    },
    titreOptions() {
      const titres = this.$store.getters["fctit/titreSelect"];
      let optArray = this.type ? titres.filter((o) => o.type == this.type) : [];

      // trie pour mettre les options 'autre' à la fin
      optArray.sort((a, b) => {
        if (a.label.toLowerCase().includes("autre")) {
          return 1;
        } else if (b.label.toLowerCase().includes("autre")) {
          return -1;
        }
      });

      return optArray;
    },
    anneeOptions() {
      return this.$store.getters["fc/optAnnee"];
    },
    moisOptions() {
      return this.$store.state.fc.optMois;
    },
    moisShow() {
      // montre le champ 'mois'
      return (
        // si cours avec mois demandé
        [2, 3, 4, 5, 6, 7, 8, 9].includes(this.form.id_fcont_titre) &&
        // et si année définie ( différente de null ou inconnue)
        /^[0-9]{4}$/.test(this.form.annee)
      );
    },
    titreCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fctit.titreCreateStatus;
    },
    fcontinueLoadStatus() {
      return this.$store.state.fc.fcontinueLoadStatus;
    },
    FCtitresIsLoaded() {
      return (
        this.$store.state.fctit.titresLoadStatus == 2 &&
        this.$store.state.fc.fcontinueLoadStatus == 2
      );
    },
    idTitre() {
      return this.form.id_fcont_titre;
    },
  },

  methods: {
    setType() {
      const fcontinues = this.$store.getters["fctit/titreSelect"];
      const idFc = this.form.id_fcont_titre;
      if (idFc) {
        // récupère le id du type
        this.type = fcontinues.filter((a) => a.value == idFc)[0].type;
      }
      // active 'Activité' si un type est choisi
      this.desactive = this.type ? false : true;
    },
    resetTitreMois() {
      const opt = this.titreOptions;
      // si une seule option, la sélectionne
      const titre = opt.length == 1 ? opt[0].value : null;
      // vide le champs 'titre' sur changement de 'type'
      this.$refs.titre.$emit("input", titre);
      // vide le champs 'mois' sur changement de 'type'
      this.$refs.mois.$emit("input", "");
      // active 'Titre' si un type est choisi
      this.desactive = this.type ? false : true;
    },
    changeAn(val) {
      // efface le mois si année inconnue ou null
      if (!/^[0-9]{4}$/.test(val)) {
        this.form.mois = null;
      }
    },
    addFcont() {
      this.dialogShowFcTitreCreate = true;
      this.$store.commit("fctit/resetTitre", this.type);
    },
  },
  watch: {
    FCtitresIsLoaded() {
      // watch le chargement de la fcontinue et aussi celui du titres si charge directement la page: 2 + 2
      if (this.FCtitresIsLoaded) {
        this.setType();
      }
    },
    idTitre() {
      // maj le type de formation sélectionné quand annule changement de type
      this.setType();
    },
    titreCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      // et l'affichage de la nouvelle activité dans le dropdown
      if (this.titreCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La formation a été ajoutée à la base de donnée",
          type: "success",
        });
        // Sélectionne le titre qui vient d'être créé
        this.form.id_fcont_titre = this.$store.state.fctit.titreLastCreatedId;
      } else if (this.titreCreateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
